function range(start:number, end:number) {
    /* generate a range : [start, start+1, ..., end-1, end] */
    let len = end - start + 1;
    let a = [];
    for (let i = 0; i < len; i++) {
        if(i !== 0 && i !== end){
            a.push(start + i)
        }
    };
    return a;
}

export default range;
