interface AirspaceStyleType {
    [key: string]: AirspaceColorType;
}

interface AirspaceColorType {
    color: string,
    fillColor: string,
    opacity: number,
    fillOpacity: number,
    weight: number,
    graphicZIndex: number,
    dashArray?: string|null,
    lineCap?: string,
    strokeDashstyle?: null
}

class Airspace {
    private static readonly global_strokeWidth = 2;
    private static readonly global_fillOpacity = 0.05;
    private static readonly global_strokeDashstyle = null;
    private static readonly global_weight = 2;
    private static readonly global_dashArray = "4 1";
    private static readonly global_graphicZIndex = 9;
    private static readonly global_orange_color = "#FF8000";
    private static readonly global_lime_color = "#00FF00";
    private static readonly global_green_color = "#008000";
    private static readonly global_blue_color = "#0000FF";
    private static readonly global_yellow_color = "#FFFF00";
    private static readonly global_yellow_color_10 = "#D4D400";
    private static readonly global_red_color = "#FF0000";
    private static readonly global_pink_color = "#994099";
    private static readonly global_violet_color = "#EE82EE";
    private static readonly global_magenta_color = "#FF00FF";
    private static readonly global_black_color = "#000000";
    private static readonly airspaceStyles:AirspaceStyleType = {
        "DEFAULT": {
            color: this.global_yellow_color,
            fillColor: this.global_yellow_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            weight: this.global_strokeWidth,
            graphicZIndex: this.global_graphicZIndex,
            dashArray: this.global_strokeDashstyle
        },
        "CTR": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            weight: this.global_strokeWidth,
            graphicZIndex: this.global_graphicZIndex,
            // lineCap: "square",
            // dashArray: "3 8"
            dashArray: this.global_dashArray
        },
        "TMZ": {
            color: this.global_black_color,
            fillColor: this.global_black_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "A": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "B": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "C": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "D": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            strokeDashstyle: this.global_strokeDashstyle
        },
        "E": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "F": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "G": {
            color: this.global_yellow_color_10,
            fillColor: this.global_yellow_color_10,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "Z": {
            color: this.global_black_color,
            fillColor: this.global_black_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "RESTRICTED": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "PROHIBITED": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "GLIDER_PROHIBITED": {
            color: this.global_black_color,
            fillColor: this.global_black_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "DANGER": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "WW": {
            color: "#7F7F7F",
            fillColor: "#7F7F7F",
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "TSA": {
            color: this.global_violet_color,
            fillColor: this.global_violet_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "TRA": {
            color: this.global_violet_color,
            fillColor: this.global_violet_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "MATZ": {
            color: this.global_red_color,
            fillColor: this.global_red_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "RECREATIONAL": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "NATURE_RESERVE": {
            color: this.global_green_color,
            fillColor: this.global_green_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "FIR": {
            color: this.global_yellow_color,
            fillColor: this.global_yellow_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "TMA": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "RADAR_ZONE": {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "INFO_SERVICE": {
            color: this.global_yellow_color,
            fillColor: this.global_yellow_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            graphicZIndex: this.global_graphicZIndex,
            weight: this.global_strokeWidth,
            dashArray: this.global_strokeDashstyle
        },
        "default":  {
            color: this.global_blue_color,
            fillColor: this.global_blue_color,
            opacity: 1,
            fillOpacity: this.global_fillOpacity,
            weight: this.global_weight,
            graphicZIndex: this.global_graphicZIndex,
            dashArray: this.global_dashArray
        }
    };
    constructor(){
    }
    getACStyle(airspace_class:string) {
        return Airspace.airspaceStyles.hasOwnProperty(airspace_class) ? Airspace.airspaceStyles[airspace_class] : Airspace.airspaceStyles["default"];
    }

}

export default Airspace;
