class Addons {
    constructor(){

    }

    p_sort(pa:any, pb:any){
        if (pa.price > 0 && pb.price <= 0){
            return -1;  // paid before free
        } else if (pb.price >0 && pa.price <=0){
            return 1;
        }
        let rega:any = /2\d{3}/g;  // year regexp
        let ya = parseInt(rega.exec(pa.key));
        let regb:any = /2\d{3}/g;  // year regexp
        let yb = parseInt(regb.exec(pb.key));
        if (!isNaN(yb) && ya > yb){  // year compare
            return -1;
        } else if (!isNaN(ya) && ya < yb){
            return 1;
        } // passes here only if both years are the same
        return pa.name.localeCompare(pb.name);  // name compare
    }

    sortProducts(products:any){
        // here we sort the products according to http://projects.dixdouze.com/projects/air-nav-pro-ios/wiki/Store#Ordering-of-items-in-the-country
        let airac_db = [];
        let flyermaps = [];
        let swisstopo = [];
        let icao_swisstopo = [];
        let icao = [];
        let icao_ign = [];
        let icao_ign_topo = [];
        let icao_sia_france = [];
        let icao_ukcaa = [];
        let icao_dfs = [];
        let threed = [];
        let appcharts =[];
        let topo=[];
        let vfr=[];
        let skyguide=[];
        let database=[];
        let geocoded = [];
        let others = [];
        let rogers = [];
        let avioportolano = [];
        let bossy = [];
        let smart_chart = [];
        let obstacles = [];
        let osmidx = [];
        let services = [];
        let weather = [];
        let avinor = [];
        let ais = [];
        let thaivfr = [];
        for(let idx=0; idx<products.length;idx++){
            let theProduct = products[idx];
            if (theProduct.key.indexOf("vector")>-1){
                smart_chart.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.airac")>-1 || theProduct.key.indexOf("ch.xample.group")>-1){
                airac_db.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.skyguide")>-1){
                skyguide.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.flyermaps")>-1) {
                flyermaps.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_dfs")>-1){
                icao_dfs.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_swisstopo")>-1) {
                icao_swisstopo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_ukcaa")>-1){
                icao_ukcaa.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.swisstopo")>-1){
                swisstopo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_ign")>-1) {
                icao_ign_topo.push(theProduct);
            } else if (theProduct.key.search(/ch\.xample\.icao_ign\.[0-9]{4}\.500k/g)>-1) {
                icao_ign.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_sia_france")>-1){
                icao_sia_france.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.appcharts")>-1){
                appcharts.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.avioportolano.")>-1) {
                avioportolano.push(theProduct)
            } else if (theProduct.key.indexOf("ch.xample.vfr")>-1){
                vfr.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.bossy")>-1){
                bossy.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.rogers.")>-1) {
                rogers.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.topo.")>-1) {
                topo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.database.")>-1){
                database.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.free_database.worldwide.obstacles")>-1){
                obstacles.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.osmidx")>-1){
                osmidx.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao")>-1){
                icao.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.services.flightplan")>-1){
                services.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.services.weather")>-1){
                weather.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.avinor")>-1) {
                avinor.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.ais")>-1){
                ais.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.thailandvfr")>-1){
                thaivfr.push(theProduct);
            } else{
                others.push(theProduct);
            }
        }
        products = smart_chart.sort(this.p_sort).concat(airac_db.sort(this.p_sort)).concat(skyguide.sort(this.p_sort)).concat(appcharts.sort(this.p_sort)).concat(avinor.sort(this.p_sort)).concat(flyermaps.sort(this.p_sort));
        products = products.concat(icao_dfs.sort(this.p_sort)).concat(icao_swisstopo.sort(this.p_sort)).concat(icao_ukcaa.sort(this.p_sort)).concat(swisstopo.sort(this.p_sort));
        // #76879
        // products = airac_db.sort(this.p_sort).concat(flyermaps.sort(this.p_sort)).concat(icao_ign.sort(this.p_sort)).concat(appcharts.sort(this.p_sort));
        // products = products.concat(skyguide.sort(this.p_sort)).concat(smart_chart.sort(this.p_sort)).concat(icao_swisstopo.sort(this.p_sort)).concat(database.sort(this.p_sort));

        products = products.concat(icao_ign_topo.sort(this.p_sort)).concat(icao_ign.sort(this.p_sort)).concat(icao_sia_france.sort(this.p_sort));
        products = products.concat(avioportolano.sort(this.p_sort)).concat(vfr.sort(this.p_sort)).concat(ais.sort(this.p_sort)).concat(thaivfr.sort(this.p_sort)).concat(bossy.sort(this.p_sort));
        products = products.concat(rogers.sort(this.p_sort));
        products = products.concat(topo.sort(this.p_sort)).concat(database.sort(this.p_sort)).concat(services.sort(this.p_sort)).concat(weather.sort(this.p_sort)).concat(obstacles.sort(this.p_sort));
        products = products.concat(osmidx.sort(this.p_sort)).concat(icao.sort(this.p_sort));
        products = products.concat(others.sort(this.p_sort));
        return products
    }

    sortCountryProducts(products:Array<any>){
        // here we sort the products according to http://projects.dixdouze.com/projects/air-nav-pro-ios/wiki/Store#Ordering-of-items-in-the-country
        let airac_db = [];
        let flyermaps = [];
        let swisstopo = [];
        let icao_swisstopo = [];
        let icao = [];
        let icao_ign = [];
        let icao_ign_topo = [];
        let icao_sia_france = [];
        let icao_ukcaa = [];
        let icao_dfs = [];
        let threed = [];
        let appcharts =[];
        let topo=[];
        let vfr=[];
        let skyguide=[];
        let database=[];
        let geocoded = [];
        let others = [];
        let rogers = [];
        let avioportolano = [];
        let bossy = [];
        let smart_chart = [];
        let obstacles = [];
        let osmidx = [];
        let services = [];
        let weather = [];
        let avinor = [];
        let ais = [];
        let thaivfr = [];
        for(let idx=0; idx<products.length;idx++){
            let theProduct = products[idx];
            if (theProduct.key.indexOf("vector")>-1){
                smart_chart.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.airac")>-1 || theProduct.key.indexOf("ch.xample.group")>-1){
                airac_db.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.skyguide")>-1){
                skyguide.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.flyermaps")>-1) {
                flyermaps.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_dfs")>-1){
                icao_dfs.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_swisstopo.2")>-1) {
                icao_swisstopo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_ukcaa")>-1){
                icao_ukcaa.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.swisstopo")>-1){
                swisstopo.push(theProduct);
            } else if (theProduct.key.search(/ch\.xample\.icao_ign\.[0-9]{4}\.500k/g)>-1) {
                icao_ign.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_ign")>-1) {
                icao_ign_topo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao_sia_france")>-1){
                icao_sia_france.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.appcharts")>-1){
                appcharts.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.avioportolano.")>-1) {
                avioportolano.push(theProduct)
            } else if (theProduct.key.indexOf("ch.xample.vfr")>-1){
                vfr.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.bossy")>-1){
                bossy.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.rogers.")>-1) {
                rogers.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.topo.")>-1) {
                topo.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.database.")>-1){
                database.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.free_database.worldwide.obstacles")>-1){
                obstacles.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.osmidx")>-1){
                osmidx.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.icao")>-1){
                icao.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.services.flightplan")>-1){
                services.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.services.weather")>-1){
                weather.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.avinor")>-1) {
                avinor.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.ais")>-1){
                ais.push(theProduct);
            } else if (theProduct.key.indexOf("ch.xample.thailandvfr")>-1){
                thaivfr.push(theProduct);
            } else{
                others.push(theProduct);
            }
        }
        products = smart_chart.sort(this.p_sort).concat(airac_db.sort(this.p_sort)).concat(skyguide.sort(this.p_sort)).concat(obstacles.sort(this.p_sort));
        products = products.concat(icao_swisstopo.sort(this.p_sort)).concat(icao_ign_topo.sort(this.p_sort)).concat(avinor.sort(this.p_sort)).concat(flyermaps.sort(this.p_sort));
        products = products.concat(icao_dfs.sort(this.p_sort)).concat(icao_sia_france.sort(this.p_sort)).concat(icao_ukcaa.sort(this.p_sort)).concat(rogers.sort(this.p_sort));
        products = products.concat(avioportolano.sort(this.p_sort)).concat(vfr.sort(this.p_sort)).concat(ais.sort(this.p_sort)).concat(thaivfr.sort(this.p_sort));
        products = products.concat(bossy.sort(this.p_sort)).concat(swisstopo.sort(this.p_sort)).concat(topo.sort(this.p_sort));
        // #76879
        // products = airac_db.sort(this.p_sort).concat(flyermaps.sort(this.p_sort)).concat(icao_ign.sort(this.p_sort)).concat(appcharts.sort(this.p_sort));
        // products = products.concat(skyguide.sort(this.p_sort)).concat(smart_chart.sort(this.p_sort)).concat(icao_swisstopo.sort(this.p_sort)).concat(database.sort(this.p_sort));

        products = products.concat(icao_ign.sort(this.p_sort));
        products = products.concat(database.sort(this.p_sort)).concat(services.sort(this.p_sort)).concat(weather.sort(this.p_sort));
        products = products.concat(osmidx.sort(this.p_sort)).concat(icao.sort(this.p_sort)).concat(appcharts.sort(this.p_sort));
        products = products.concat(others.sort(this.p_sort));
        return products
    }
}

export default Addons;
