import unitToFeet from "./unitToFeet";

type ObsType = {
    [key: string]: string|null;
}

interface ObsIconType {
    type: string,
    isLight: string | "Y",
    elevation: number
}

class Obstacle {
    private static readonly lightedObstacleIconImageForType:ObsType = {
        other: "00_300ftLightedObstacle.png",
        aerial: "00_300ftLightedObstacle.png",
        aerialrailway: null, // LineStrings
        anemometer: "00_300ftLightedObstacle.png",
        antenna: "00_300ftLightedObstacle.png",
        barrier: "00_300ftLightedObstacle.png",
        building: "00_300ftLightedObstacle.png",
        cable: null, // LineStrings
        chimney: "06_Chimney.png",
        church: "07_Church.png",
        crane: "00_300ftLightedObstacle.png",
        factory: "08_Factory.png",
        forest: null,
        hill: "09_Mountains.png",
        lift: null, // LineStrings
        line: null, // LineStrings
        mast: "00_300ftLightedObstacle.png",
        mill: "15_WindTurbineLighted.png",
        mountain: "09_Mountains.png",
        natural: null,
        pole: "00_300ftLightedObstacle.png",
        powersupport: "10_PowerSupport.png",
        radar: "00_300ftLightedObstacle.png",
        sign: "00_300ftLightedObstacle.png",
        silo: "00_300ftLightedObstacle.png",
        spire: "00_300ftLightedObstacle.png",
        tank: "11_Tank.png",
        terrain: null,
        tower: "13_TowerLighted.png",
        tree: "00_300ftLightedObstacle.png",
        vegetation: null,
        waterpipe: null, // LineStrings
        windturbine: "15_WindTurbineLighted.png"
    };
    private static readonly notLightedObstacleIconImageForType:ObsType = {
        other: "00_300ftLightedObstacle.png",
        aerial: "00_300ftLightedObstacle.png",
        aerialrailway: null, // LineStrings
        anemometer: "00_300ftLightedObstacle.png",
        antenna: "00_300ftLightedObstacle.png",
        barrier: "00_300ftLightedObstacle.png",
        building: "00_300ftLightedObstacle.png",
        cable: null, // LineStrings
        chimney: "06_Chimney.png",
        church: "07_Church.png",
        crane: "00_300ftLightedObstacle.png",
        factory: "08_Factory.png",
        forest: null,
        hill: "09_Mountains.png",
        lift: null, // LineStrings
        line: null, // LineStrings
        mast: "00_300ftLightedObstacle.png",
        mill: "15_WindTurbineLighted.png",
        mountain: "09_Mountains.png",
        natural: null,
        pole: "00_300ftLightedObstacle.png",
        powersupport: "10_PowerSupport.png",
        radar: "00_300ftLightedObstacle.png",
        sign: "00_300ftLightedObstacle.png",
        silo: "00_300ftLightedObstacle.png",
        spire: "00_300ftLightedObstacle.png",
        tank: "11_Tank.png",
        terrain: null,
        tower: "13_TowerLighted.png",
        tree: "00_300ftLightedObstacle.png",
        vegetation: null,
        waterpipe: null, // LineStrings
        windturbine: "15_WindTurbineLighted.png"
    };
    constructor(){}

    getObsIconType(options:ObsIconType, url:string):string|undefined{
        // @string type
        // @string isLight
        // @number elevation
        const { type, isLight, elevation} = options;
        try {
            if(type === null){
                console.error("Missing type data")
            } else if(isLight === null){
                console.error("Missing light data")
            } else if(elevation === null) {
                console.error("Missing elevation data");
            } else if(url === null){
                console.error("Missing url");
            } else {
                const obstacle_root = url + "obstacles_icons/";
                let tempType: string = type.toLowerCase().replace(/\s/g, "");
                if (unitToFeet(elevation) > 500) {
                    if (isLight === "Y") {
                        return obstacle_root + "04_500ftLightedObstacle.png";
                    } else {
                        return obstacle_root + "05_500ftObstacle.png";
                    }
                } else {
                    if (isLight === "Y") {
                        if (tempType in Obstacle.lightedObstacleIconImageForType && Obstacle.lightedObstacleIconImageForType[tempType]) {
                            return obstacle_root + Obstacle.lightedObstacleIconImageForType[tempType];
                        } else {
                            return obstacle_root + "05_500ftObstacle.png";
                        }
                    } else {
                        if (tempType in Obstacle.notLightedObstacleIconImageForType && Obstacle.notLightedObstacleIconImageForType[tempType]) {
                            return obstacle_root + Obstacle.notLightedObstacleIconImageForType[tempType];
                        } else {
                            return obstacle_root + "05_500ftObstacle.png";
                        }
                    }
                }
            }
        } catch (err){
            return undefined;
        }
    }

    getObsIcon(type:string, isLight:boolean){
        let isFound = null;
        if(isLight){
            isFound = Obstacle.lightedObstacleIconImageForType[type];
        } else {
            isFound = Obstacle.notLightedObstacleIconImageForType[type];
        }
        if(isFound){
            return isFound;
        } else {
            console.error("Light Obstacle image not found");
        }
    }

    checkLightedObstacle(type:string):boolean {
        return type in Obstacle.lightedObstacleIconImageForType && Obstacle.lightedObstacleIconImageForType.hasOwnProperty(type);
    }
}

export default Obstacle;
