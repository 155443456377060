class Waypoints {
    constructor(){

    }
    getWpIconType(waypoint_type:number, url:any):string {
        let wpType = parseInt(String(waypoint_type));
        // const waypoint_root = static_url_root + "waypoints_icons/";
        const anp8_waypoint_root = url + "waypoints_icons/anp8/";
        switch (wpType) {
            case 1: //airport
                return anp8_waypoint_root + "anp8_aerodrome.png";
            case 3: //wp
                return anp8_waypoint_root + "anp8_user.png";
            case 2: //fix_b
                return anp8_waypoint_root + "anp8_reporting.png";
            case 4: //helipad
                return anp8_waypoint_root + "anp8_heliport.png";
            case 5: //seaplane base
                return anp8_waypoint_root + "anp8_seaplane.png";
            case 6: //ulm field
                return anp8_waypoint_root + "anp8_ultralight.png";
            case 7: //balloon field
                return anp8_waypoint_root + "anp8_balloon.png";
            case 8: //IFR
                return anp8_waypoint_root + "anp8_ifr.png";
            case 9: //glider field
                return anp8_waypoint_root + "anp8_glider.png";
            case 10: //dme
                return anp8_waypoint_root + "anp8_dme.png";
            case 11: //ndb
                return anp8_waypoint_root + "anp8_ndb.png";
            case 12: //vor
                return anp8_waypoint_root + "anp8_vor.png";
            case 13: //ndbme
                return anp8_waypoint_root + "anp8_ndbdme.png";
            case 14: //vordme
                return anp8_waypoint_root + "anp8_vordme.png";
            case 15: //tacan
                return anp8_waypoint_root + "anp8_tacan.png";
            case 16: //vortac
                return anp8_waypoint_root + "anp8_vortac.png";
            default: //wp
                return anp8_waypoint_root + "anp8_user.png";
        }
    }
}

export default Waypoints;
