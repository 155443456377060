function getQueryParams(qs:any) {
    qs = qs.split('+').join(' ');
    let params:any = {},
        tokens:any,
        re = /[?&]?([^=]+)=([^&]*)/g;
    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}

export default getQueryParams;
