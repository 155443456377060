function bindData(dataObj:any, element?:string){
    const el:string = element ? element : 'bind_data';
    if(document.getElementById(el) !== null){
        let jsonBindData:undefined|null|string = document.getElementById(el)?.textContent;
        if(jsonBindData){
            let bindData = JSON.parse(jsonBindData);

            for(let data in bindData){
                if(typeof data !== "undefined"){
                    if (bindData[data] && bindData[data][0] === "{") {
                        dataObj[data] = JSON.parse(bindData[data]);
                    } else {
                        dataObj[data] = bindData[data];
                    }

                }
            }
        } else {
            console.error(`${el} element not found`);
        }
    }
}

export default bindData;
